import { useMutation } from '@tanstack/react-query'
import { LakehouseConnectionTypeEnum, LakehouseSnowflakeConnectionInput } from '@vendia/management-api-types'
import debug from 'debug'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { SnowflakeConnection } from 'src/components/lakehouse/snowflake-connection'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:Snowflake')

export const StepConnectSnowflake: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const snowflake = {
    authType: 'PASSWORD',
    ...form.useStore((s) => s.values.source?.snowflake),
  }

  const getTablesMutation = useMutation({
    mutationFn: () =>
      api.getLakehouseTables({
        connectionInput: {
          type: LakehouseConnectionTypeEnum.Snowflake,
          snowflakeConnection: {
            ...(snowflake as LakehouseSnowflakeConnectionInput),
          },
        },
      }),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      logger('snowflake connection response', response)
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.getLakehouseTables) {
        notify.error('Error testing Snowflake connection')
        return
      }
      form.setFieldValue('source.availableTables', response.getLakehouseTables.tables as string[])
      form.setFieldValue('source.sourceRegion', response.getLakehouseTables.region as string)
      context.goToStep(StepId.SelectTable)
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Connect to Snowflake'
        description={'Let’s authenticate your Snowflake account'}
      />
      <ScrollableStepContent inset>
        <SnowflakeConnection context={context as any} />
      </ScrollableStepContent>

      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {([canSubmit]) => (
          <StepButtons
            context={context}
            isSubmitting={getTablesMutation.isPending}
            nextBlocked={getTablesMutation.isPending || !canSubmit}
            onNext={() => getTablesMutation.mutate()}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
