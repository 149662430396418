import { ShareApp, Uni } from '@vendia/management-api-types'
import { ComponentType } from 'react'
import { useParams } from 'react-router'
import Loader from 'src/components/loaders/page-loader'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

export const LakehouseDataLoader = ({ Comp }: { Comp: ComponentType<{ uni: Uni; shareApps: ShareApp[] }> }) => {
  const { id: uniName } = useParams()
  const getUniQuery = useGetUni()

  const shareAppsQuery = useGetShareApps({ uniName })
  const uni = getUniQuery?.data?.getUni as Uni | undefined

  if (!uni || !uniName || !shareAppsQuery.data) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }
  return <Comp uni={uni} shareApps={shareAppsQuery.data as ShareApp[]} />
}
