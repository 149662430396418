import { LakehouseSyncScheduleEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import CheckboxGroupField from 'src/components/fields/checkbox-group.field'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { isRequiredValidator } from 'src/utils/form/validation'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import { StepId } from '../config'
import { lakehouseRegionOptions } from '../utils'

const logger = debug('app:CreateDataProduct')

export const CreateDataProductStep: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const navigate = useNavigate()
  const flowMode = form.useStore((state) => state.values.flowMode)
  const productNameRef = useFirstFocus()

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        large
        centered
        title={flowMode === 'create' ? 'Configure your first data product' : 'Add a data product'}
        description={
          <>
            <p>A data product is a tailored data set created to meet the specific needs of your customers.</p>
            {/* TODO: put into tooltip maybe? too much content for current layout... <p>
              Examples include:
              <ul>
                <li>
                  Trial data sets: Smaller versions of your data, offered for free or at a low cost to allow potential
                  customers to explore its value.
                </li>
                <li>
                  GDPR-compliant sets: Data sets that have been processed to ensure compliance with data privacy
                  regulations like GDPR.
                </li>
                <li>
                  Enriched data sets: Data combined with other sources to add value (e.g., demographic data appended to
                  customer purchase history).
                </li>
                <li>
                  Machine learning-ready sets: Data specifically prepared for use in training machine learning models
                  (e.g., labeled and cleaned).
                </li>
              </ul>
              And so much more.
            </p> */}
          </>
        }
      />
      <ScrollableStepContent inset className='pt-8'>
        <div className='w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-4'>
            <TextField
              name='product.name'
              label='Name'
              placeholder='Enter name'
              description='Name your data product'
              ref={productNameRef}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.CreateLakehouse,
                validator: isRequiredValidator(),
              })}
              useNestedLabel
              form={context.form}
            />
            <ListboxField
              name='product.syncSchedule'
              label='Frequency (Refresh rate)'
              description='How often should this data product be updated?'
              options={[
                { value: LakehouseSyncScheduleEnum.OnDemand, label: 'On-demand' },
                { value: LakehouseSyncScheduleEnum.Daily, label: 'Daily' },
                { value: LakehouseSyncScheduleEnum.Weekly, label: 'Weekly' },
                { value: LakehouseSyncScheduleEnum.Monthly, label: 'Monthly' },
              ]}
              form={context.form}
              useNestedLabel
            />
          </div>
        </div>
      </ScrollableStepContent>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isPristine]}>
        {([canSubmit, isSubmitting, isPristine]) => (
          <StepButtons
            context={context}
            hasPrevious={flowMode === 'create'}
            hasCancel={flowMode !== 'create'}
            onCancel={flowMode !== 'create' ? () => navigate('../', { relative: 'path' }) : undefined}
            isSubmitting={isSubmitting}
            nextBlocked={!canSubmit || isPristine}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
