import { useMutation } from '@tanstack/react-query'
import {
  CspLowerEnum,
  DataPolicyColumnEffect,
  LakehouseAppInput,
  LakehouseConnectionInput,
  LakehouseConnectionTypeEnum,
  LakehouseSnowflakeConnectionInput,
  LakehouseSyncScheduleEnum,
  LakehouseTableInput,
} from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { VendiaFormApi } from 'src/components/fields/form'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { useDevSettings } from 'src/pages/developer-settings.page'
import useApi from 'src/utils/hooks/use-api'
import useListUnis from 'src/utils/hooks/use-list-unis'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'
import { generateShortId } from 'src/utils/short-id'
import { userState } from 'src/utils/state'
import { validateSchema } from 'src/utils/validate-schema'

import { DEFAULT_REGION, SOURCE_NODE_NAME, StepId } from '../config'
import { buildUniFqn, buildUniNameFromAlias, generateNodesForApi, snowflakeToAwsRegionMap } from '../utils'
import { StepChooseSource } from './choose-source.step'
import { StepConnectCloudera } from './connect-cloudera.step'
import { StepConnectSnowflake } from './connect-snowflake.step'
import { StepNameApplication } from './create-application.step'
import { StepCreateColumnSharingPermissions } from './create-column-sharing-permissions.step'
import { CreateDataProductStep } from './create-data-product.step'
import { StepCreateRowSharingPermissions } from './create-row-sharing-permissions.step'
import { StepReviewSharingPermissions } from './review-sharing-permissions.step'
import { StepSelectTable } from './select-table.step'
import { StepSelectUpdateColumns } from './select-update-column.step'

const logger = debug('app:LakehouseFlow')

const LakehouseFlow = () => {
  const { devSettings } = useDevSettings()
  const { listUnisQuery } = useListUnis()
  const userData = useRecoilValue<any>(userState)
  const navigate = useNavigate()
  const api = useApi()

  const createLakehouseMutation = useMutation({
    mutationFn: (input: LakehouseAppInput) => api.createLakehouseApp({ input }),
    onError: (error) => {
      notify.error(`Error creating Uni: ${error}`)
    },
    onSuccess: (response) => {
      logger('response', response)
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.createLakehouseApp) {
        notify.error('Error creating Uni')
        return
      }
      // TODO: better success state
      notify.success('Application created successfully')

      // Navigate to dashboard
      listUnisQuery.refetch()
      navigate(`/uni/${response.createLakehouseApp.uniName}`)
    },
  })

  const flowErrorMessages = {
    schema: 'Schema is invalid',
    product: 'Data product will return no data',
  } as const

  type FormProps = {
    value: LakehouseFlowStepValues
    formApi: VendiaFormApi<LakehouseFlowStepValues>
  }

  const formValidators = {
    onSubmitAsync: async ({ value, formApi }: FormProps) => {
      logger('formValidator onSubmit', { value, formApi })
      const { product, schema } = value
      let schemaError = {} as any

      if (schema) {
        const [error] = await validateSchema(schema)
        schemaError = error ? { schema: flowErrorMessages.schema } : {}
      }

      const invalid =
        product?.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude && product?.columnPolicies.length === 0
      const productErrors = invalid ? [flowErrorMessages.product] : []

      const result = {
        form: schemaError?.schema ?? invalid ? flowErrorMessages.product : undefined,
        fields: { ...schemaError, ...productErrors },
      }
      logger('formValidator onChange result', result)
      return result
    },
  }

  const createLakehouse = async ({ value, formApi }: FormProps) => {
    function distributionNodeNameFromRegion(region: string) {
      return `distribution-node-${region}`
    }
    const { product, schema, source, alias, namespace, randomSuffix } = value

    const generatedUniName = buildUniNameFromAlias(alias, randomSuffix)
    const name = buildUniFqn(generatedUniName, namespace)

    // Create a simple node config (similar to config we build in StepAddNodes) — one node for each region!
    type Node = { name: string; description?: string; csp: CspLowerEnum; region: string }

    // We also need one node for the "source" which pulls data from Snowflake
    const sourceNode: Node = {
      name: SOURCE_NODE_NAME,
      csp: CspLowerEnum.Aws,
      // Set source region to match snowflake region if there's a match with a region we support
      region: snowflakeToAwsRegionMap[source.sourceRegion] ?? DEFAULT_REGION,
    }
    // Then format with extra details just like we do in standard create uni flow
    const nodesInput = generateNodesForApi(userData?.email, [sourceNode])

    const connectionId = generateShortId({ existingIds: [] })
    let connectionInput: LakehouseConnectionInput | undefined = undefined
    if (source.connection === 'snowflake') {
      connectionInput = {
        name: 'My first connection',
        id: connectionId,
        type: LakehouseConnectionTypeEnum.Snowflake,
        snowflakeConnection: source.snowflake as LakehouseSnowflakeConnectionInput,
      }
    } else if (source.connection === 'cloudera') {
      connectionInput = {
        name: 'My first connection',
        id: connectionId,
        type: LakehouseConnectionTypeEnum.Cloudera,
        clouderaConnection: source.cloudera,
      }
    }

    // Build full product input from form data
    const tableKey = generateShortId({ existingIds: [] })
    const tableInput: LakehouseTableInput = {
      key: tableKey,
      name: product.name,
      syncSchedule: product.syncSchedule,
      tableDefinition: {
        columnPolicies: product.columnPolicies,
        rowPolicies: product.rowPolicies,
        defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
      },
      sourceConnectionId: connectionId,
      sourceTableDefinitions: [
        {
          tableName: source.selectedTable,
          columns: value.selectedTableColumns,
          lastUpdatedColumn: value.lastUpdatedColumn,
          lastUpdatedColumnFormat: value.lastUpdatedColumnFormat,
          keyColumns: value.keyColumns,
        },
      ],
    }

    if (source) {
      const input: LakehouseAppInput = {
        name: name,
        alias: alias,
        schema: schema,
        nodes: nodesInput,
        connections: [connectionInput!],
        tables: [tableInput],
        shareAppVersion: devSettings?.shareAppVersion ?? undefined,
      }
      // Await to keep function from returning so final step isSubmitting
      //  state keeps finish button disabled after user clicks it
      await createLakehouseMutation.mutateAsync(input)
    } else {
      notify.error('No source defined')
    }
  }

  return (
    <MultiStepFlow<LakehouseFlowStepValues>
      key='entitlements'
      onSubmit={createLakehouse as any}
      validators={formValidators as any}
      initialFlowState={{
        flowMode: 'create',
        alias: '',
        randomSuffix: generateShortId({ existingIds: [] }),
        schema: '',
        product: {
          name: '',
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
          syncSchedule: LakehouseSyncScheduleEnum.OnDemand,
        },
        source: {
          connection: undefined,
          availableTables: [],
          selectedTable: '',
          sourceRegion: '',
        },
        selectedTableColumns: [],
        keyColumns: [''],
      }}
      flowSteps={[
        {
          id: StepId.CreateLakehouse,
          StepComponent: StepNameApplication,
        },
        {
          id: StepId.ChooseSource,
          StepComponent: StepChooseSource,
        },
        {
          id: StepId.ConnectSnowflake,
          StepComponent: StepConnectSnowflake,
          showInStepNav: false,
        },
        {
          id: StepId.ConnectCloudera,
          StepComponent: StepConnectCloudera,
          showInStepNav: false,
        },
        {
          id: StepId.SelectTable,
          StepComponent: StepSelectTable,
        },
        {
          id: StepId.SelectUpdateColumns,
          StepComponent: StepSelectUpdateColumns,
        },
        {
          id: StepId.DataProducts,
          StepComponent: CreateDataProductStep,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
          showInStepNav: false,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
      ]}
    />
  )
}

export default LakehouseFlow
