import {
  DataPolicyColumnEffect,
  LakehouseSyncScheduleEnum,
  LakehouseTableInput,
  ShareApp,
  Uni,
} from '@vendia/management-api-types'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { SOURCE_NODE_NAME, StepId } from 'src/pages/uni-create/config'
import { StepChooseSource } from 'src/pages/uni-create/flow-lakehouse/choose-source.step'
import { StepConnectCloudera } from 'src/pages/uni-create/flow-lakehouse/connect-cloudera.step'
import { StepConnectSnowflake } from 'src/pages/uni-create/flow-lakehouse/connect-snowflake.step'
import { StepCreateColumnSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-column-sharing-permissions.step'
import { StepCreateRowSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-row-sharing-permissions.step'
import { CreateVendiaTable } from 'src/pages/uni-create/flow-lakehouse/create-table.step'
import { StepJoinVendiaTables } from 'src/pages/uni-create/flow-lakehouse/join-vendia-tables.step'
import { StepReviewSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/review-sharing-permissions.step'
import { StepSelectTable } from 'src/pages/uni-create/flow-lakehouse/select-table.step'
import { StepSelectUpdateColumns } from 'src/pages/uni-create/flow-lakehouse/select-update-column.step'
import { StepSelectVendiaTables } from 'src/pages/uni-create/flow-lakehouse/select-vendia-tables.step'
import { parseLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import useApi from 'src/utils/hooks/use-api'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { generateShortId } from 'src/utils/short-id'

import { LakehouseDataLoader } from './lakehouse-loader'

export const CreateVendiaTableFlow = () => <LakehouseDataLoader Comp={CreateVendiaTableFlowContent} />

export const CreateVendiaTableFlowContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const { node: nodeName } = useParams()
  const ingestionShareApp = shareApps.find(
    (shareApp) => shareApp.shareAppName === ShareAppName.LakehouseIngestion && shareApp.nodeName === nodeName,
  )
  const shareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(ingestionShareApp?.shareAppConfig ?? ''),
    [ingestionShareApp?.shareAppConfig],
  )

  const navigate = useNavigate()
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni.name })
  const api = useApi()

  const existingTables = shareAppConfig.tables

  return (
    <MultiStepFlow
      initialFlowState={{
        flowMode: 'createTable',
        uniName: uni.name,
        nodeName: SOURCE_NODE_NAME,
        // TODO this field is still named 'product' for now, but it really should be "table" or "target" for something more generic
        product: {
          name: '',
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
          syncSchedule: LakehouseSyncScheduleEnum.OnDemand,
          selectedVendiaTables: [],
        },
        source: {
          connection: undefined,
          availableTables: [],
          selectedTable: '',
          sourceRegion: '',
        },
        keyColumns: [],
        existingVendiaTables: existingTables,
        existingConfig: shareAppConfig,
      }}
      flowSteps={[
        {
          id: StepId.CreateVendiaTable,
          StepComponent: CreateVendiaTable,
        },
        {
          id: StepId.ChooseSource,
          StepComponent: StepChooseSource,
        },
        {
          id: StepId.ConnectSnowflake,
          StepComponent: StepConnectSnowflake,
          showInStepNav: false,
        },
        {
          id: StepId.ConnectCloudera,
          StepComponent: StepConnectCloudera,
          showInStepNav: false,
        },
        {
          id: StepId.SelectTable,
          StepComponent: StepSelectTable,
          showInStepNav: false,
        },
        {
          id: StepId.SelectVendiaTables,
          StepComponent: StepSelectVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.JoinVendiaTables,
          StepComponent: StepJoinVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.SelectUpdateColumns,
          StepComponent: StepSelectUpdateColumns,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
      ]}
      onSubmit={async ({ value }) => {
        const { product, source } = value
        // Keep track of shortIds to avoid collisions
        const existingIds = existingTables.map((product) => product.key)
        // Build full product input from form data
        const shortId = generateShortId({ existingIds })
        existingIds.push(shortId)
        const newTableInput: LakehouseTableInput = {
          key: shortId,
          name: product.name,
          syncSchedule: product.syncSchedule,
          tableDefinition: {
            columnPolicies: product.columnPolicies,
            rowPolicies: product.rowPolicies,
            defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
          },
          // TODO: this is unfinished temporary stuff! Will need to update this flow, resolver, etc, just making types work for now
          sourceConnectionId: existingTables[0].sourceConnectionId,
          sourceTableDefinitions: [
            {
              tableName: source.selectedTable,
              columns: value.selectedTableColumns,
              lastUpdatedColumn: value.lastUpdatedColumn,
              lastUpdatedColumnFormat: value.lastUpdatedColumnFormat,
              keyColumns: value.keyColumns,
            },
          ],
        }

        const res = await api.addLakehouseTable({
          input: {
            uniName: uni.name,
            nodeName: SOURCE_NODE_NAME,
            table: newTableInput,
          },
        })

        refetchShareApps()
        navigate(`/uni/${uni.name}`)
      }}
    />
  )
}
