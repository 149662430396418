import {
  DataPolicyColumnEffect,
  DataPolicyOperator,
  DataPolicyRowEffect,
  SnowflakeTableColumnInput,
} from '@vendia/management-api-types'
import { ListOption } from 'src/components/inputs/listbox.input'

import { LakehouseTable } from './types'

export const rowPermissionOptions: ListOption[] = [
  {
    value: DataPolicyRowEffect.Exclude,
    label: 'Exclude',
    description: 'Exclude this row from the data product',
  },
  {
    value: DataPolicyRowEffect.Allow,
    label: 'Allow',
    description: 'Include this row in the data product',
  },
]

export function getColumnPermissionOptions(defaultEffect?: DataPolicyColumnEffect): ListOption[] {
  const excludeAll = defaultEffect === DataPolicyColumnEffect.Exclude
  const allowAll = defaultEffect === DataPolicyColumnEffect.Allow

  return [
    {
      value: DataPolicyColumnEffect.Exclude,
      label: 'Exclude',
      description: excludeAll ? 'All columns are excluded by default' : 'Exclude this column from the data product',
      disabled: excludeAll,
    },
    {
      value: DataPolicyColumnEffect.Allow,
      label: 'Allow',
      description: allowAll ? 'All columns are allowed by default' : 'Include this column in the data product',
      disabled: allowAll,
    },
    {
      value: DataPolicyColumnEffect.Mask,
      label: 'Mask entire value',
      description: 'Replace the entire value with *',
    },
    {
      value: DataPolicyColumnEffect.MaskExceptLast,
      label: 'Mask everything except the last',
      description: 'Replace all but the last number of characters provided with *',
    },
    {
      value: DataPolicyColumnEffect.Replace,
      label: 'Replace entire value with',
      description: 'Replace the entire value with the provided value',
    },
  ]
}

export const getPolicyEffectLabel = (
  effect: any,
  defaultColumnPolicyEffect?: DataPolicyColumnEffect,
): string | undefined => {
  const options = getColumnPermissionOptions(defaultColumnPolicyEffect)
  return options.find((option) => option.value === effect)?.label as string
}

export const dataProductOperatorOptions: ListOption[] = [
  {
    value: DataPolicyOperator.Equals,
    label: 'Equal to',
  },
  {
    value: DataPolicyOperator.GreaterThan,
    label: 'Greater than',
  },
  {
    value: DataPolicyOperator.GreaterThanOrEqual,
    label: 'Greater than or equal to',
  },
  {
    value: DataPolicyOperator.In,
    label: 'In',
  },
  {
    value: DataPolicyOperator.IsNotNull,
    label: 'Not null',
  },
  {
    value: DataPolicyOperator.IsNull,
    label: 'Null',
  },
  {
    value: DataPolicyOperator.LessThan,
    label: 'Less than',
  },
  {
    value: DataPolicyOperator.LessThanOrEqual,
    label: 'Less than or equal to',
  },
  {
    value: DataPolicyOperator.Like,
    label: 'Like',
  },
  {
    value: DataPolicyOperator.NotEquals,
    label: 'Not equal to',
  },
  {
    value: DataPolicyOperator.NotIn,
    label: 'Not in',
  },
  {
    value: DataPolicyOperator.NotLike,
    label: 'Not like',
  },
]

export const getOperatorLabel = (operator?: DataPolicyOperator) =>
  dataProductOperatorOptions.find((option) => option.value === operator)?.label as string

export function getColumnOptions(columns: SnowflakeTableColumnInput[] = [], policies: any[] = []): ListOption[] {
  const configuredColumns = policies?.map((policy) => policy.name)

  return columns.map((column) => {
    const disabled = configuredColumns.includes(column.name)
    const description = disabled
      ? 'Column has been configured'
      : `${column.snowflakeType}${column.nullable ? '' : ' (not nullable)'}`

    return {
      value: column.name,
      label: column.name,
      description,
      disabled,
    }
  })
}

export const isAllAllowed = ({ columnPolicies, defaultColumnPolicyEffect }: LakehouseTable) =>
  columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Allow) ||
  (columnPolicies.length === 0 && defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow)

export const isAllExcluded = ({ columnPolicies }: LakehouseTable) =>
  columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Exclude)
