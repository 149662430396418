import { UniSkuEnum } from '@vendia/management-api-types/src/generated/graphql/graphql'
import clsx from 'clsx'
import debug from 'debug'
import { Link, useLocation, useParams } from 'react-router-dom'
import Icon from 'src/components/icons/icon'
import { UniFeatures } from 'src/types/types'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

const logger = debug('app:side-nav-update')

export interface LinkItem {
  path: string
  label: string
  iconName: string
  isActive: boolean
  isSettings?: boolean
  // Used when extra paths are nested under the main path
  additionalMatchingPaths?: string[]
}

export function UniSideNav() {
  const { id: uniName, node } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni
  const nodeZero = uni?.nodes?.[0]
  const nodeName = node || nodeZero?.name
  const basePath = `/uni/${uniName}/${nodeName}/`
  const isLakehouseUni = uni?.features?.includes(UniFeatures.VENDIA_TABLES_ENABLED)
  const links: LinkItem[] = [
    ...(isLakehouseUni
      ? [
          {
            path: 'data',
            label: 'Tables',
            iconName: 'sidenav-data-view',
            isActive: false,
          },
        ]
      : []),
    {
      path: `entities`,
      label: 'Data View',
      iconName: 'sidenav-data-view',
      isActive: false,
    },
    {
      path: `files`,
      label: 'Files',
      iconName: 'sidenav-folder-open',
      isActive: false,
    },
    {
      path: `analyze`,
      label: 'Analyze',
      iconName: 'sidenav-analyze',
      isActive: false,
      additionalMatchingPaths: ['transactions'],
    },
    {
      path: 'workflows',
      label: 'Workflows',
      iconName: 'sidenav-connectors',
      isActive: false,
    },
    {
      path: 'share',
      label: 'Share',
      iconName: 'sidenav-handshake',
      isActive: false,
    },
    {
      path: `settings`,
      label: 'Settings',
      iconName: 'sidenav-settings-gear',
      isActive: false,
      isSettings: true,
    },
  ]

  const location = useLocation()
  const linkListItems = links?.map((link) => {
    const path = `${basePath}${link.path}`
    // Normalize trailing slash and compare to find active path
    const currentPath = location.pathname.replace(/\/+$/, '')
    const currentLinkPath = path.replace(/\/+$/, '')
    // "loose match" for entity explorer (see old sidenav)
    const isActive =
      currentPath.includes(currentLinkPath) ||
      (link.additionalMatchingPaths?.some((p) => currentPath.includes(`/${p}`)) ?? false)
    link.isActive = isActive
    link.path = path
    return <LinkItem key={path} linkItem={link} />
  })
  if (!nodeName || !uni) {
    return <div className='px-2' />
  }
  const itemsExceptSettings = linkListItems.slice(0, linkListItems.length - 1)
  const settingsItem = linkListItems[linkListItems.length - 1]
  return (
    // static width needed to center fixed settings item at bottom within the sidebar
    <div className={'w-28 flex-none bg-white px-4 pt-8'}>
      <div className='between flex h-full flex-1 flex-col gap-12 pb-6'>
        <div className='flex flex-col gap-12'>{itemsExceptSettings}</div>
        <div className='flex flex-1'>
          <div className='fixed bottom-6'>{settingsItem}</div>
        </div>
      </div>
    </div>
  )
}

function LinkItem({ linkItem }: { linkItem: LinkItem }) {
  const { path, label, iconName, isActive } = linkItem
  return (
    <Link to={path} aria-label={label}>
      <div
        className={clsx(
          'w-20', // Static width to correctly position settings (since it's fixed)
          isActive && 'bg-fuchsia-50 font-bold text-fuchsia-700',
          'flex flex-col items-center justify-center rounded-lg p-2 transition-colors duration-300 hover:bg-fuchsia-50 hover:text-fuchsia-700',
        )}
      >
        <div className=''>
          <Icon name={iconName} size={48} className={clsx(isActive && 'text-fuchsia-700')} />
        </div>
        <div className='text-xs font-semibold'>{label}</div>
      </div>
    </Link>
  )
}
