export enum CreateFlow {
  SELECT_TYPE = 'select-type',
  STANDARD = 'standard',
  DISTRO = 'lakehouse',
}

export enum StepId {
  SelectUniType = 'select_uni_type',
  CreateNetwork = 'create_network',
  CreateLakehouse = 'create_lakehouse',
  ChooseSource = 'choose_source',
  ConnectSnowflake = 'connect_snowflake',
  ConnectCloudera = 'connect_cloudera',
  SelectTable = 'select_table',
  DataProducts = 'data_products',
  SharingPermissions = 'sharing_permissions',
  ColumnSharingPermissions = 'column_sharing_permissions',
  RowSharingPermissions = 'row_sharing_permissions',
  AddNodes = 'add_nodes',
  SchemaEditMode = 'schema_edit_mode',
  SchemaDesigner = 'schema_designer',
  InvitePartners = 'invite_partners',
  Confirmation = 'confirmation',
  SelectUpdateColumns = 'select_update_columns',
  CreateVendiaTable = 'create_vendia_table',
  SelectVendiaTables = 'select_vendia_tables',
  JoinVendiaTables = 'join_vendia_tables',
}

export const SOURCE_NODE_NAME = 'source-node'

export const DEFAULT_REGION = 'us-west-2'
