import { StepComponent } from 'src/components/flows/types'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

export const StepJoinVendiaTables: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const { joinTables } = context.form.useStore((state) => state.values)
  return (
    <>
      <h1>Table info</h1>
      {joinTables?.map((table, index) => (
        <div key={index}>
          <h3>{table.tableName}</h3>
          <ul>
            {table.columnsInfo.map((column: any, colIndex: any) => (
              <li key={colIndex}>
                {column.column_name}: {column.data_type}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}
