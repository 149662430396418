import { useParams } from 'react-router-dom'
import SideNav from 'src/components/navigation/side-nav'
import { UniFeatures } from 'src/types/types'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

export default function UniSettingsNav({ uniPending = false }: { uniPending?: boolean }) {
  const { id: uniName, node: nodeName } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni
  const isLakehouseUni = uni?.features?.includes(UniFeatures.VENDIA_TABLES_ENABLED)
  let links = [
    {
      name: 'Resources',
      path: 'resources',
      disabled: uniPending,
    },
    {
      name: 'Authentication',
      path: 'auth-settings',
      disabled: uniPending,
    },
    {
      name: 'Success notifications',
      path: 'success-notifications',
      disabled: uniPending,
    },
    {
      name: 'Error notifications',
      path: 'error-notifications',
      disabled: uniPending,
    },
    {
      name: 'Smart contracts',
      path: 'smart-contracts',
      disabled: uniPending,
    },
    {
      name: 'Schema',
      path: 'schema',
    },
    {
      name: 'Uni Settings',
      path: 'uni-settings',
    },
    {
      name: 'Danger zone',
      path: 'danger-zone',
    },
  ]

  return (
    <SideNav
      basePath={`/uni/${uniName}/${nodeName}/settings/`}
      isActiveLooseMatch
      links={links}
      backLink={{
        name: 'Back',
        path: isLakehouseUni ? '../data' : '../entities',
        relative: 'path',
      }}
    />
  )
}
