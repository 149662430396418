import { useNavigate } from 'react-router'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { SourceButton } from 'src/components/lakehouse/source-button'
import { isRequiredValidator } from 'src/utils/form/validation'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import { StepId } from '../config'

export const StepChooseSource: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const { flowMode, existingVendiaTables } = form.useStore((state) => state.values)
  const hasVendiaTableNames = !!(existingVendiaTables && existingVendiaTables.length)
  const navigate = useNavigate()

  form.useField({
    name: 'source.connection',
    validators: lakehouseFlowFieldValidator<LakehouseFlowStepValues, any>({
      stepId: StepId.ChooseSource,
      validator: isRequiredValidator(),
    }),
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Choose your data source'
        description={
          hasVendiaTableNames
            ? 'Connect an external data warehouse or use your existing Vendia table(s)'
            : 'Connect your existing data warehouse'
        }
      />
      <ScrollableStepContent inset>
        <div className='mt-8 flex h-64 w-3/4 flex-wrap justify-center gap-4'>
          <SourceButton
            label='Snowflake'
            description='Connect to your data in Snowflake'
            imageSrc='/images/vendor/snowflake.png'
            context={context}
            connection='snowflake'
            to={StepId.ConnectSnowflake}
            linkText='Click here to connect'
          />
          <SourceButton
            label='Cloudera'
            description='Connect to your data in Cloudera'
            imageSrc='/images/vendor/cloudera.png'
            connection='cloudera'
            context={context}
            to={StepId.ConnectCloudera}
            linkText='Click here to connect'
          />
          <SourceButton
            label='Existing Connection'
            description='Use the existing connection already configured in this uni'
            imageSrc='/images/rocket.svg'
            connection='existing'
            context={context}
            to={StepId.SelectTable}
          />
          {hasVendiaTableNames && (
            <SourceButton
              label='Vendia Tables'
              description='Use your existing Vendia Table(s) as a data source'
              imageSrc='/logo-symbol.svg'
              connection='vendia'
              context={context}
              to={StepId.SelectVendiaTables}
              linkText='Click here to configure'
            />
          )}
        </div>
      </ScrollableStepContent>
      <StepButtons
        context={context}
        hasNext={false}
        hasPrevious={flowMode === 'create' || flowMode === 'createTable'}
        hasCancel={flowMode !== 'create' && flowMode !== 'createTable'}
      />
    </StepWrapper>
  )
}
