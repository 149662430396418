import { useQuery } from '@tanstack/react-query'
import { ShareApp, ShareAppStatus, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import debug from 'debug'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import ConnectionBanner from 'src/components/messages/connection-banner'
import PageHeader from 'src/components/page-header'
import StatusPill from 'src/components/pills/status-pill'
import { SOURCE_NODE_NAME } from 'src/pages/uni-create/config'
import { parseLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useInterval } from 'src/utils/hooks/use-interval'

import { LakehouseDataLoader } from './lakehouse-loader'
import { ShareDataProductModal } from './share-data-product-modal'
import { TableCard } from './table-card'
import { useLakehouseJobs } from './use-lakehouse-jobs'

const logger = debug('app:lakehouse-overview')

dayjs.extend(relativeTime)

enum Status {
  Idle,
  ShareModalOpen,
}

export const PageLakehouseOverview = () => <LakehouseDataLoader Comp={PageLakehouseOverviewContent} />

const PageLakehouseOverviewContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const api = useApi()
  const [selectedProductToShare, setSelectedProductToShare] = useState<string | null>(null)
  const sourceNode = uni.nodes?.find((node) => node.name === SOURCE_NODE_NAME)
  const { node: nodeName } = useParams()

  // While deploying, poll for updates to we can refresh the UI
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni.name })
  const anyShareAppsDeploying = shareApps.some((shareApp) =>
    [ShareAppStatus.Pending, ShareAppStatus.Deploying].includes(shareApp.shareAppStatus),
  )

  const lakehouseJobsQuery = useLakehouseJobs({
    uniName: uni.name,
    nodeName: nodeName!,
    enabled: shareApps.length > 0 && !anyShareAppsDeploying,
  })

  useInterval(() => {
    if (anyShareAppsDeploying) {
      refetchShareApps()
    }
  }, 30 * 1000)

  // Refetch lakehouse jobs when share apps are done deploying or they'll continue to display "loading..."
  const prevAnyShareAppsDeploying = useRef(anyShareAppsDeploying)
  useEffect(() => {
    if (prevAnyShareAppsDeploying.current && !anyShareAppsDeploying) {
      lakehouseJobsQuery.refetch()
    }
    prevAnyShareAppsDeploying.current = anyShareAppsDeploying
  }, [anyShareAppsDeploying])

  const ingestionShareApp = shareApps.find(
    (shareApp) => shareApp.shareAppName === ShareAppName.LakehouseIngestion && shareApp.nodeName === nodeName,
  )

  const shareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(ingestionShareApp?.shareAppConfig),
    [ingestionShareApp?.shareAppConfig],
  )

  const connections = shareAppConfig.connections
  // Single source for now & single table for now!
  const connectionConfig = connections[0]
  const connectionType = connectionConfig?.type // SNOWFLAKE or CLOUDERA

  const isLakehouseReady = uni.status === 'RUNNING' && !anyShareAppsDeploying

  const [status, setStatus] = useState(Status.Idle)

  const openShareDataProductModal = (index: number) => {
    setSelectedProductToShare(shareAppConfig.tables[index].key)
    setStatus(Status.ShareModalOpen)
  }

  const closeShareDataProductModal = () => {
    setSelectedProductToShare(null)
    setStatus(Status.Idle)
  }

  let statusPill =
    uni.status && uni.status !== 'RUNNING' ? <StatusPill status={uni.status} data-testid='uni-status' /> : null

  if (!statusPill && anyShareAppsDeploying) {
    statusPill = <StatusPill status={ShareAppStatus.Deploying} data-testid='uni-status' />
  }

  const hasZeroTables = shareAppConfig.tables.length === 0
  return (
    <>
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={'Tables'}
          titleAfter={statusPill}
          actions={
            <>
              <Button
                kind='secondary'
                icon='plus-m'
                iconSize={14}
                to='../data/create-vendia-table'
                disabled={!isLakehouseReady}
                className='mr-2'
              >
                Create Table
              </Button>
              <Button
                kind='secondary'
                icon='sidenav-settings-gear'
                iconSize={14}
                to='../data/settings'
                disabled={!isLakehouseReady}
              >
                Settings
              </Button>
            </>
          }
          testid='uni-name-header'
        />
        {hasZeroTables ? (
          <div className='flex w-full p-6'>
            <div className='flex h-[500px] w-full flex-col items-center justify-center gap-4'>
              TODO: NICE EMPTY VIEW WITH ADD TABLE CTA / BUTTON
            </div>
          </div>
        ) : (
          <div className='flex p-6'>
            <div className='flex w-full flex-col gap-4'>
              {shareAppConfig.tables.map((table, index) => (
                <TableCard
                  key={table.key}
                  uni={uni}
                  table={table}
                  tableIndex={index}
                  connection={connections.find((c) => c.id === table.sourceConnectionId) ?? connections[0]}
                  setShareDataProduct={openShareDataProductModal}
                  lakehouseJobsQueryResult={lakehouseJobsQuery}
                  isLakehouseReady={isLakehouseReady}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {shareAppConfig.tables.length === 0 ? (
        <ShareDataProductModal
          uni={uni}
          isOpen={status === Status.ShareModalOpen}
          onClose={closeShareDataProductModal}
          defaultProductKey={selectedProductToShare}
          shareAppConfig={shareAppConfig}
        />
      ) : null}
    </>
  )
}

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <h5 className='text-neutral-9 text-xs font-bold'>{children}</h5>
)

const BigMetric = ({ children }: { children: React.ReactNode }) => <div className='text-2xl font-bold'>{children}</div>
